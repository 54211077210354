import Lady from "./lady.jpeg";
import "./Masha.css";

export default function Masha() {
	return (
		<div className="lady-pi">
			<img className="lady-pic" src={Lady} alt="gamer lady" />
		</div>
	);
}
